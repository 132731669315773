<template>
  <div>
    <Header />
    <v-app class="expertise-wrapped">
      <ScrollToTop />
      <v-container>
        <h1 class="text-center my-10 font-weight-black expertise-header">
          {{ $t("expertise_uppercase") }}
        </h1>
        <v-row>
          <v-col cols="12" md="6" class="mb-10">
            <v-card>
              <v-card-title class="head-patent d-flex justify-space-between align-center">
                <v-card-title-text>
                  <span class="headline">{{ $t("patent") }}</span>
                </v-card-title-text>
                <router-link to="/expertise/patent">
                  <v-card-title-text>
                    <v-btn outlined color="white" title="Learn more">
                      <v-icon dark> mdi-arrow-top-right </v-icon>
                    </v-btn>
                  </v-card-title-text>
                </router-link>
              </v-card-title>
              <hr class="m-0" />
              <v-card-text>
                <p>
                  {{ $t("patent_1") }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="mb-10">
            <v-card>
              <v-card-title class="head-trademark d-flex justify-space-between align-center">
                <v-card-title-text>
                  <span class="headline">{{ $t("trademarks") }}</span>
                </v-card-title-text>
                <router-link to="/expertise/trademark">
                  <v-card-title-text>
                    <v-btn outlined color="white" title="Learn more">
                      <v-icon dark> mdi-arrow-top-right </v-icon>
                    </v-btn>
                  </v-card-title-text>
                </router-link>
              </v-card-title>
              <hr class="m-0" />
              <v-card-text>
                <p>
                  {{ $t("trademark_1") }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="mb-10">
            <v-card>
              <v-card-title class="head-industrial-design d-flex justify-space-between align-center">
                <v-card-title-text>
                  <span class="headline">{{ $t("industrial_design") }}</span>
                </v-card-title-text>
                <router-link to="/expertise/industrial-design">
                  <v-card-title-text>
                    <v-btn outlined color="white" title="Learn more">
                      <v-icon dark> mdi-arrow-top-right </v-icon>
                    </v-btn>
                  </v-card-title-text>
                </router-link>
              </v-card-title>
              <hr class="m-0" />
              <v-card-text>
                <p>
                  {{ $t("design_line_1") }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="mb-10">
            <v-card>
              <v-card-title class="head-copyright d-flex justify-space-between align-center">
                <v-card-title-text>
                  <span class="headline">{{ $t("copyright") }}</span>
                </v-card-title-text>
                <router-link to="/expertise/copyright">
                  <v-card-title-text>
                    <v-btn outlined color="white" title="Learn more">
                      <v-icon dark> mdi-arrow-top-right </v-icon>
                    </v-btn>
                  </v-card-title-text>
                </router-link>
              </v-card-title>
              <hr class="m-0" />
              <v-card-text>
                <p>
                  {{ $t("copyright_1") }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header-2.vue";
import Footer from "@/components/Footer.vue";
import ScrollToTop from "@/components/ScrollToTop.vue";

export default {
  name: "Expertise",
  components: {
    Header,
    Footer,
    ScrollToTop
  },
};
</script>

<style scoped>
.v-card__subtitle,
.v-card__text {
  height: 14em;
  font-size: 0.9em;
}

.expertise-header {
  letter-spacing: 10px;
}

.expertise-wrapped {
  margin-top: 6.5em;
  margin-bottom: 3.5em;
  background-size: cover !important;
}

.head-patent {
  background-color: #143990b3;
  color: white;
}

.head-trademark {
  background-color: #1b8d36b3;
  color: white;
}

.head-industrial-design {
  background-color: #c606c3b3;
  color: white;
}

.head-copyright {
  background-color: #b9b918b3;
  color: white;
}

@media only screen and (min-width: 787px) and (max-width: 1024px) {
  .v-card__text {
    height: auto;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .v-card__text {
    height: auto;
  }
}

@media only screen and (max-width: 480px) {
  .v-card__text {
    height: auto;
  }

  .expertise-content {
    padding: 0 3em 0;
    text-align: justify;
  }
}
</style>
